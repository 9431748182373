import React from "react";
const WebTop = React.lazy(() => import("../../pages/WebTop"));
const NewOrder = React.lazy(() => import("../../pages/Order/New"));
const Order = React.lazy(() => import("../../pages/Order"));
const OrderConfirm = React.lazy(() => import("../../pages/Order/Confirm"));
const OrderSuccess = React.lazy(() => import("../../pages/Order/Success"));
const Setting = React.lazy(() => import("../../pages/Setting"));
const Request = React.lazy(() => import("../../pages/Request"));
const OrderDetail = React.lazy(() => import("../../pages/Order/Show"));

const routes = [
  {
    name: "発注",
    path: "/line/orders/new",
    component: NewOrder,
    exact: false,
  },
  {
    name: "発注内容",
    path: "/line/orders/confirm",
    component: OrderConfirm,
  },
  {
    name: "発注内容",
    path: "/line/orders/success",
    component: OrderSuccess,
  },
  {
    name: "発注",
    path: "/line",
    component: WebTop,
    exact: true,
  },
  {
    name: "発注履歴",
    path: "/line/orders",
    component: Order,
    exact: true,
  },
  {
    name: "発注履歴",
    path: "/line/orders/:id",
    component: OrderDetail,
  },
  {
    name: "見積り依頼",
    path: "/line/requests",
    component: Request,
    exact: true,
  },
  {
    name: "設定",
    path: "/line/settings",
    component: Setting,
    exact: true,
  },
];
export default routes;
