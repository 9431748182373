import React from "react";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import store from "@store";
import "./style.scss";

const Login = () => {
  const { isAuthenticated } = store();
  if (isAuthenticated) return <Redirect to="/line" />;
  return (
    <Container>
      <Header />
      <div className="login-wrapper">
        <div className="login-content text-center">
          <div className="login-title">LINE ログイン</div>
          <a className="login-btn d-block align-self-center" href="/auth/line">
            <img src="/images/line/icons/line-messenger.svg" className="mr-3" />
            LINEログイン
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Login;
