/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import store from "@store";

const LoginVerify = (props) => {
  const { setAppState, isAuthenticated } = store();
  const accessToken = props.location.search.split("access_token=")[1];

  useEffect(() => {
    if (accessToken) {
      fetch(`/auth/verify-current-user?access_token=${accessToken}`)
        .then((response) => response.json())
        .then(({ current_user }) => {
          if (current_user) {
            localStorage.setItem("line:accessToken", accessToken);
            setAppState({
              currentUser: current_user,
              isAuthenticated: true,
            });
          }
        });
    }
  }, [accessToken]);

  if (isAuthenticated)
    return (
      <Redirect
        to={{ pathname: "/line", state: { accessToken: accessToken } }}
      />
    );

  return (
    <div className="d-flex p-5 align-items-center justify-content-center">
      <Spinner animation="border" />
    </div>
  );
};

export default LoginVerify;
